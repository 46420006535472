import React, { FC } from 'react'
import { SelectOption } from '../types'
import Select from './Select'

type Props = {
  trimesters: SelectOption[]
  institutions: SelectOption[]
  years: SelectOption[]
  setTrimester: (val: string) => void
  setInstitution: (val: string) => void
  setYear: (val: string) => void
}

const Filters: FC<Props> = ({ trimesters, setTrimester, institutions, setInstitution, years, setYear }) => (
  <aside className="w-full justify-center flex flex-row flex-wrap items-center px-3 py-5">
    <div className="flex flex-col py-2 md:pr-7 w-full min-w-[240px] max-w-[360px]">
      <label className="text-light-black font-bold" htmlFor="institución">
        Institución
      </label>
      <Select id="institución" onChange={(e) => setInstitution(e.target.value)}>
        {institutions.map((opt) => (
          <option value={opt.value} key={opt.value}>
            {opt.label}
          </option>
        ))}
      </Select>
    </div>
    <div className="flex flex-col py-2 md:pr-7 w-full min-w-[240px] max-w-[360px]">
      <label className="text-light-black font-bold" htmlFor="año">
        Año
      </label>
      <Select id="año" onChange={(e) => setYear(e.target.value)}>
        {years.map((opt) => (
          <option value={opt.value} key={opt.value}>
            {opt.label}
          </option>
        ))}
      </Select>
    </div>
    <div className="flex flex-col py-2 md:pr-7 w-full min-w-[240px] max-w-[360px]">
      <label className="text-light-black font-bold" htmlFor="trimestre">
        Trimestre
      </label>
      <Select id="trimestre" onChange={(e) => setTrimester(e.target.value)}>
        {trimesters.map((opt) => (
          <option value={opt.value} key={opt.value}>
            {opt.label}
          </option>
        ))}
      </Select>
    </div>
  </aside>
)

export default Filters
