import React, { FC, useEffect, useState } from 'react'
import { MapContainer, TileLayer, GeoJSON } from 'react-leaflet'
import { scaleQuantile } from 'd3'
import { LatLngExpression, Layer, Map as MapType } from 'leaflet'
import Legend from './Legend'
import Summary from './Summary'
import mx from '../assets/geojson/mexico-states.geo.json'
import { Request, FeatureLayer } from '../types'
import { GeoJsonObject } from 'geojson'

type Props = {
  requests: Request
  institution: string
  className?: string
}

const MEXICAN_STATES = mx as GeoJsonObject

const popupContent = (name: string, solicitudes: number, dictamenPericial: number) => `
  <div>
    <p class="state">${name}</p>
    <p class="requests">Solicitudes: ${solicitudes}</p>
    <p class="requests">Dictamen pericial: ${dictamenPericial}</p>
  </div>
`

const Map: FC<Props> = ({ requests, institution }) => {
  const [map, setMap] = useState<MapType | null>(null)
  const [position] = useState<LatLngExpression>([23.6345, -102.5528])

  const getColor = (d: number | undefined) => {
    if (d) {
      const colors = ['#181717', '#8c798d', '#b6aae2', '#d479e7', '#6b3875', '#be1fcf']
      const solicitudesSet = new Set([...requests.values()].map((d) => d.oficiosSolicitud))
      const grades = [...solicitudesSet].sort((a, b) => a - b)

      const color = scaleQuantile<string>().domain(grades).range(colors)
      return color(d)
    }
    return '#ffffff'
  }

  const style = (feature?: GeoJSON.Feature) => ({
    fillColor:
      institution === 'Comisión Nacional de Búsqueda de Personas' || institution === 'Fiscalía General de la República'
        ? getColor(requests.get(institution)?.oficiosSolicitud)
        : getColor(requests.get(feature?.properties?.name || '')?.oficiosSolicitud),
    weight: 0.5,
    strokeWidth: 1,
    opacity: 0.5,
    color: '#ffffff',
    fillOpacity: 0.5,
  })

  const onEachFeature = (feature: GeoJSON.Feature, layer: Layer) => {
    const name = feature.properties?.name || ''
    const solicitudes = requests.get(feature.properties?.name)?.oficiosSolicitud || 0
    const dictamenPericial = requests.get(feature.properties?.name)?.dictamenPericial || 0
    const popup = popupContent(name, solicitudes, dictamenPericial)

    layer.bindPopup(popup)
  }

  useEffect(() => {
    if (map) {
      map.eachLayer((layer) => {
        const popup = layer.getPopup()

        if (popup) {
          const feature = (layer as FeatureLayer).feature
          const name = feature.properties?.name || ''
          const solicitudes = requests.get(feature.properties?.name)?.oficiosSolicitud || 0
          const dictamenPericial = requests.get(feature.properties?.name)?.dictamenPericial || 0

          popup.setContent(popupContent(name, solicitudes, dictamenPericial))
        }
      })
    }
  }, [map, requests])

  return (
    <>
      <MapContainer id="Map" className="h-[500px] max-w-[1000px]" center={position} zoom={5} scrollWheelZoom zoomControl whenCreated={setMap}>
        <TileLayer
          attribution='Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery &copy; <a href="https://www.mapbox.com/">Mapbox</a>'
          url="https://api.mapbox.com/styles/v1/mapbox/light-v10/tiles/{z}/{x}/{y}@2x?access_token=pk.eyJ1Ijoiam9yYW5kcmFkZWZpZyIsImEiOiJjajh5aTljamkyOXI4MzJwMDU1c3J0Y3NhIn0.-bd9fiJYHnXM0bmj0mhArA"
        />
        <GeoJSON key="my-geojson" data={MEXICAN_STATES} style={style} onEachFeature={onEachFeature} />
        <Legend map={map} requests={requests} />
        <Summary institution={institution} map={map} requests={requests} />
      </MapContainer>
    </>
  )
}

export default Map
