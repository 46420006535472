import React, { useState, useEffect, useMemo } from 'react'
import { csv as d3csv, DSVParsedArray, rollup, sum } from 'd3'
import Map from './components/Map'
import { Request, BusquedaPersonasDataColumn, BusquedaPersonasDataRow, SelectOption } from './types'
import { Serie } from '@nivo/line'
import LineChart from './components/LineChart'
import Filters from './components/Filters'

const csv = 'https://docs.google.com/spreadsheets/d/e/2PACX-1vRj_Amh1zydeoZxOMv52oQRa2_Xy43KLsI7zuTnnc3zZ4yVWoq1zCJREsFTmwSwpQ/pub?gid=1601490888&single=true&output=csv'
// const TOTAL_OPT = {
//   label: 'Total',
//   value: 'total',
// } as const
const TODOS_OPT = {
  label: 'Todos',
  value: 'Todos',
} as const
// const confirmadosOpts = [
//   {
//     ...TOTAL_OPT,
//   },
//   {
//     label: 'Confirmados',
//     value: 'confirmados',
//   },
//   {
//     label: 'No confirmados',
//     value: 'no-confirmados',
//   },
// ] as const

// ConfirmadosValues = typeof confirmadosOpts[number]['value']

const reduceData = (data: DSVParsedArray<BusquedaPersonasDataRow> | BusquedaPersonasDataRow[]) =>
  rollup(
    data,
    (v) =>
      v
        .map((d) => {
          return {
            dictamenPericial: Number(d['Personas confirmadas con dictamen pericial']),
            huellaConfronta: Number(d['Personas con huella para confronta']),
            coincidenciasIdentificacion: Number(d['Personas con coincidencias para identificación']),
            oficiosSolicitud: Number(d['Oficios de solicitud']),
          }
        })
        .reduce((prev, current) => ({
          dictamenPericial: prev.dictamenPericial + current.dictamenPericial,
          huellaConfronta: prev.huellaConfronta + current.huellaConfronta,
          coincidenciasIdentificacion: prev.coincidenciasIdentificacion + current.coincidenciasIdentificacion,
          oficiosSolicitud: prev.oficiosSolicitud + current.oficiosSolicitud,
        })),
    (d) => d['Institución']
  )

function App(): JSX.Element {
  const [requests, setRequests] = useState<Request | null>(null)
  const [trimesters, setTrimesters] = useState<SelectOption[]>([TODOS_OPT])
  const [institutions, setInstitutions] = useState<SelectOption[]>([TODOS_OPT])
  const [years, setYears] = useState<SelectOption[]>([TODOS_OPT])
  const [trimester, setTrimester] = useState('Todos')
  const [institution, setInstitution] = useState('Todos')
  const [year, setYear] = useState('Todos')
  const [fetchedData, setFetchedData] = useState<BusquedaPersonasDataRow[] | null>(null)
  const [filteredData, setFilteredData] = useState<BusquedaPersonasDataRow[] | null>(null)
  const [chartData, setChartData] = useState<Serie[]>([])

  const getData = async () => {
    try {
      const data = await d3csv<BusquedaPersonasDataRow, BusquedaPersonasDataColumn>(csv, (row) => ({
        Caso: row.Caso || '',
        Año: row['Año'] || '',
        Trimestre: row.Trimestre || '',
        Institución: row['Institución'] || '',
        'Oficios de solicitud': row['Oficios de solicitud'] || '',
        'Personas con coincidencias para identificación': row['Personas con coincidencias para identificación'] || '',
        'Personas con huella para confronta': row['Personas con huella para confronta'] || '',
        'Personas confirmadas con dictamen pericial': row['Personas confirmadas con dictamen pericial'] || '',
        measureableData: [
          {
            label: 'Oficios de solicitud',
            value: row['Oficios de solicitud'] ? Number(row['Oficios de solicitud']) : 0,
            year: row['Año'] || '',
            trimester: row.Trimestre || '',
            institution: row['Institución'] || '',
          },
          {
            label: 'Personas con huella para confronta',
            value: row['Personas con coincidencias para identificación'] ? Number(row['Personas con coincidencias para identificación']) : 0,
            year: row['Año'] || '',
            trimester: row.Trimestre || '',
            institution: row['Institución'] || '',
          },
          {
            label: 'Personas con coincidencias para identificación',
            value: row['Personas con huella para confronta'] ? Number(row['Personas con huella para confronta']) : 0,
            year: row['Año'] || '',
            trimester: row.Trimestre || '',
            institution: row['Institución'] || '',
          },
          {
            label: 'Personas confirmadas con dictamen pericial',
            value: row['Personas confirmadas con dictamen pericial'] ? Number(row['Personas confirmadas con dictamen pericial']) : 0,
            year: row['Año'] || '',
            trimester: row.Trimestre || '',
            institution: row['Institución'] || '',
          },
        ],
      }))

      const initialFilteredData = [...data]

      setFetchedData(initialFilteredData)

      const groups = reduceData(initialFilteredData)
      const flattenedData = initialFilteredData.flat()

      const yearsSet = new Set(flattenedData.map((row) => row['Año']))
      const yearsArray = Array.from(yearsSet).map((row) => ({
        label: row,
        value: row,
      }))
      const yearsOpt = [{ ...TODOS_OPT }, ...yearsArray]

      const trimesterSet = new Set(flattenedData.map((row) => row['Trimestre']))
      const trimesterArray = Array.from(trimesterSet).map((row) => ({
        label: row,
        value: row,
      }))
      const trimesterOpt = [{ ...TODOS_OPT }, ...trimesterArray]

      const institutionsSet = new Set(flattenedData.map((row) => row['Institución']))
      const institutionsArray = Array.from(institutionsSet)
        .map((row) => ({
          label: row,
          value: row,
        }))
        .sort((a, b) => {
          const fa = a.label.toLowerCase()
          const fb = b.label.toLowerCase()

          if (fa < fb) {
            return -1
          }
          if (fa > fb) {
            return 1
          }
          return 0
        })
      const institutionsOpt = [{ ...TODOS_OPT }, ...institutionsArray]

      setRequests(groups)
      setYears(yearsOpt)
      setTrimesters(trimesterOpt)
      setInstitutions(institutionsOpt)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    if (filteredData) {
      const filteredMeasurableData = filteredData.map((data) => data.measureableData).flat()
      const groupedTrimesterData: Serie[] = Array.from(
        rollup(
          filteredMeasurableData,
          (v) => sum(v.map((d) => d.value)),
          (d) => d.label,
          (d) => `${d.year}-${d.trimester}`
        ),
        ([id, data]) => {
          const entries = [...data.entries()]

          return {
            id,
            data: entries.map((e, i) => ({
              x: e[0],
              y: e[1],
            })),
          }
        }
      )

      setChartData(groupedTrimesterData)
    }
  }, [filteredData])

  useMemo(() => {
    if (fetchedData !== null) {
      const yearFilteredData = fetchedData.filter((row) => {
        if (year === 'Todos') {
          return true
        }

        return row['Año'] === year
      })

      const trimesterFilteredData = yearFilteredData.filter((row) => {
        if (trimester === 'Todos') {
          return true
        }

        return row['Trimestre'] === trimester
      })

      const institutionFilteredData = trimesterFilteredData.filter((row) => {
        if (institution === 'Todos') {
          return true
        }

        return row['Institución'] === institution
      })

      const groups = reduceData(institutionFilteredData)

      setFilteredData(institutionFilteredData)
      setRequests(groups)
    }
  }, [trimester, institution, year, fetchedData])

  return (
    <div className="App flex flex-col items-center" id="App">
      <h2 className="text-2xl py-3 px-5 font-bold text-center text-light-black">
        Intercambio de información de huellas dactilares de las entidades federativas con el INE con fines de identificación humana
      </h2>
      <Filters years={years} trimesters={trimesters} institutions={institutions} setYear={setYear} setInstitution={setInstitution} setTrimester={setTrimester} />
      <aside className="flex flex-row flex-wrap items-center px-3 pb-5">
        <a
          className="font-bold underline text-light-black hover:text-shiny-pink visited:text-light-eggplant"
          href="https://docs.google.com/spreadsheets/d/1NpyiqIJ3lc7khy9UvUj9zzLRXzCGncPk/edit?usp=sharing&ouid=109195516468513495141&rtpof=true&sd=true"
          target="_blank"
          rel="noopener noreferrer"
        >
          Ver todos los datos
        </a>
      </aside>
      <section className="w-full max-w-[1000px]">{requests && <Map className="h-[500px] max-w-[1000px]" institution={institution} requests={requests} />}</section>
      <section className="w-full min-h-[400px] h-[500px] max-w-[1000px]">
        <h4 className="py-5 font-bold text-center text-xl">Datos a lo largo de los trimestres</h4>
        {requests && <LineChart data={chartData} institution={institution} />}
      </section>
    </div>
  )
}

export default App
