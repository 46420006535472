import { FC, useEffect, useState } from 'react'
import { Control, Map, DomUtil, Point } from 'leaflet'
import { Request } from '../types'

type Props = {
  map: Map | null
  requests: Request
  institution: string
}

const defaultControl = () => new Control()

const Summary: FC<Props> = ({ map, requests, institution }) => {
  const [control] = useState(defaultControl())

  useEffect(() => {
    if (map) {
      const oficiosSolicitud = [...requests.values()].map((d) => d.oficiosSolicitud)
      const coincidenciasIdentificacion = [...requests.values()].map((d) => d.coincidenciasIdentificacion)
      const dictamenPericial = [...requests.values()].map((d) => d.dictamenPericial)
      const huellaConfronta = [...requests.values()].map((d) => d.huellaConfronta)
      const intlFormat = new Intl.NumberFormat('es-MX')
      const summary = {
        oficiosSolicitud: oficiosSolicitud.length > 0 ? [...oficiosSolicitud].reduce((acc, d) => acc + d) : 0,
        coincidenciasIdentificacion: coincidenciasIdentificacion.length > 0 ? [...coincidenciasIdentificacion].reduce((acc, d) => acc + d) : 0,
        dictamenPericial: dictamenPericial.length > 0 ? [...dictamenPericial].reduce((acc, d) => acc + d) : 0,
        huellaConfronta: huellaConfronta.length > 0 ? [...huellaConfronta].reduce((acc, d) => acc + d) : 0,
      }

      control.setPosition('topleft')
      control.onAdd = () => {
        const div = DomUtil.create('div', 'flex flex-col flex-nowrap px-2 bg-light-pink-ish/75 text-light-black min-h-[150px] min-w-[270px]')

        DomUtil.setPosition(div, new Point(20, 0))

        div.innerHTML = `
          <div class="text-center text-lg border-b py-2 border-white border-opacity-70">
            <p class="font-bold">${institution}</p>
          </div>
          <div class="text-center border-b py-2 border-white border-opacity-70">
            <p>Oficios de solicitud</p>
            <p class="font-bold">${intlFormat.format(summary.oficiosSolicitud)}</p>
          </div>
          <div class="text-center border-b py-2 border-white border-opacity-70">
            <p>Personas con huella para confronta</p>
            <p class="font-bold">${intlFormat.format(summary.huellaConfronta)}</p>
          </div>
          <div class="text-center border-b py-2 border-white border-opacity-70">
            <p>Personas con coincidencias para identificación</p>
            <p class="font-bold">${intlFormat.format(summary.coincidenciasIdentificacion)}</p>
          </div>
          <div class="text-center py-2">
            <p>Personas confirmadas con dictamen pericial</p>
            <p class="font-bold">${intlFormat.format(summary.dictamenPericial)}</p>
          </div>
        `

        return div
      }

      control.addTo(map)
    }

    return () => {
      control.remove()
    }
  }, [map, institution, requests, control])

  return null
}

export default Summary
