// src/AppRouter.tsx

import React, { FC } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
// import { RecoilRoot } from 'recoil'
import { Provider } from 'react-redux'
import store from './redux/store'
import App from './App'

const AppRouter: FC = () => (
  <Router>
    <Provider store={store}>
      <Switch>
        <Route exact path="/" component={App} />
      </Switch>
    </Provider>
  </Router>
)

// const AppRouter = () => (
//   <Router>
//     <RecoilRoot>
//       <Suspense fallback={<span>Loading...</span>}>
//         <Switch>
//           <Route exact path="/" component={App} />
//         </Switch>
//       </Suspense>
//     </RecoilRoot>
//   </Router>
// )

/*
// TODO EE: To replace Recoil with Redux Toolkit;

import { Provider } from 'react-redux'
import store from './redux/store'

<Router>
  <Provider store={store}>
    <Switch>
      <Route exact path="/" component={App} />
    </Switch>
  </Provider>
</Router>

 */

/*

// TODO: EE: Without Recoil or Redux Toolkit;

// src/AppRouter.tsx

import React, { FunctionComponent } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import App from './App'

const AppRouter: FunctionComponent = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={App} />
      </Switch>
    </Router>
  )
}

 */

export default AppRouter
