import { FC, useEffect, useState } from 'react'
import { Control, Map, DomUtil, Point } from 'leaflet'
import { scaleQuantile } from 'd3'
import { Request } from '../types'

type Props = {
  map: Map | null
  requests: Request
}

const defaultLegend = () => new Control({ position: 'bottomleft' })

const Legend: FC<Props> = ({ map, requests }) => {
  const [legend] = useState(defaultLegend())

  useEffect(() => {
    if (map) {
      const solicitudesSet = new Set([...requests.values()].map((d) => d.oficiosSolicitud))
      const solicitudesArr = [...solicitudesSet]
      const grades = solicitudesArr.length > 0 ? solicitudesArr.sort((a, b) => a - b) : [0]

      legend.onAdd = () => {
        const div = DomUtil.create('div', 'flex font-bold flex-row flex-nowrap items-center')
        const colors: string[] = []

        DomUtil.setPosition(div, new Point(20, -40))

        div.innerHTML = `<p class="fixed top-0 ${grades.length > 1 ? 'left-1/3' : 'left-3'} text-center">Solicitudes</p>`

        for (let i = 0; i < grades.length; i++) {
          const isGradeDeltaBigEnough = grades[i + 1] && grades[i + 1] - grades[i] > 1
          const isElementHeadOrTail = i === 0 || i === grades.length - 1
          const doesColorAlreadyExist = !colors.includes(getColor(grades[i]))

          const shouldColorGradeAppear = doesColorAlreadyExist && (isElementHeadOrTail || isGradeDeltaBigEnough)

          if (i === 0) {
            div.innerHTML += `
              <div class="flex flex-col flex-nowrap items-center">
                <div style="padding-top:1.625rem;" class="p-2 text-2xl">
                  -
                </div>
                <div>
                  ${grades[i]}
                </div>
              </div>`
          }
          if (shouldColorGradeAppear) {
            div.innerHTML += `
              <div style="width: 35px; height: 20px; background:${grades[i] > 0 ? getColor(grades[i]) : '#FFF'}" class="opacity-50"></div>
            `

            colors.push(getColor(grades[i]))
          }
          if (i === grades.length - 1) {
            div.innerHTML += `
              <div class="flex flex-col flex-nowrap items-center">
                <div style="padding-top:1.625rem;" class="p-2 text-2xl">
                  +
                </div>
                <div>
                  ${grades[i]}+
                </div>
              </div>`
          }
        }

        return div
      }

      legend.addTo(map)
    }

    return () => {
      legend.remove()
    }
  }, [map, requests, legend])

  function getColor(d: number) {
    const colors = ['#181717', '#8c798d', '#b6aae2', '#d479e7', '#6b3875', '#be1fcf']
    const solicitudesSet = new Set([...requests.values()].map((d) => d.oficiosSolicitud))
    const grades = [...solicitudesSet].sort((a, b) => a - b)

    const color = scaleQuantile<string>().domain(grades).range(colors)

    return color(d)
  }

  return null
}

export default Legend
