import React, { FC } from 'react'
import { ResponsiveLine, Serie } from '@nivo/line'

type Props = {
  data: Serie[]
  institution: string
}

const LineChart: FC<Props> = ({ data, institution }) => (
  <ResponsiveLine
    data={data}
    margin={{ top: 10, right: 300, bottom: 50, left: 60 }}
    colors={{ scheme: 'dark2' }}
    xScale={{ type: 'point' }}
    yScale={{
      nice: true,
      type: 'linear',
      min: 'auto',
      max: 'auto',
      stacked: false,
      reverse: false,
    }}
    axisTop={null}
    axisRight={null}
    axisBottom={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: 'Trimestre',
      legendOffset: 36,
      legendPosition: 'middle',
    }}
    axisLeft={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: `Total de ${institution === 'Todos' ? 'todas las instituciones' : institution}`,
      legendOffset: -40,
      legendPosition: 'middle',
    }}
    pointSize={7}
    pointColor={{ theme: 'background' }}
    pointBorderWidth={2}
    pointBorderColor={{ from: 'serieColor' }}
    pointLabelYOffset={-12}
    useMesh={true}
    enableSlices="x"
    legends={[
      {
        anchor: 'bottom-right',
        direction: 'column',
        justify: false,
        translateX: 100,
        translateY: 0,
        itemsSpacing: 0,
        itemDirection: 'left-to-right',
        itemWidth: 80,
        itemHeight: 20,
        itemOpacity: 0.75,
        symbolSize: 12,
        symbolShape: 'circle',
        symbolBorderColor: 'rgba(0, 0, 0, .5)',
        effects: [
          {
            on: 'hover',
            style: {
              itemBackground: 'rgba(0, 0, 0, .03)',
              itemOpacity: 1,
            },
          },
        ],
      },
    ]}
  />
)

export default LineChart
